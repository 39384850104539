import React from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';
import asterLogo from '../assets/logo-aster.png';
const CustomNavbar = ({ keycloak }) => {

    const userData = {
        decodeUser: () => {
            return JSON.parse(localStorage.getItem("decodedVP"));
        },
    }
    const user = userData.decodeUser();
    function ExtractLetter(chaine) {
        if (chaine && typeof chaine === 'string') {
            const letter = chaine.charAt(0).toUpperCase();
            return letter;
        } else {
            return 'U';
        }
    }
    async function loginToKeycloak() {
        await keycloak.login({ idpHint: 'oidc' })
    }
    function firstLettreToMajuscule(str) {
        if (typeof str !== 'string' || str.length === 0) {
            return str;
        }
        return user !== null ? str.charAt(0).toUpperCase() + str.slice(1) : 'U';
    }
    return (
        <Navbar expand="lg" className='catalog-nav row px-3 px-lg-3'>
            <Container className='container-nav'>
                <div>
                    <Navbar.Brand href="#">
                        <img className='img-service' alt="Gaia-x logo" src={asterLogo} style={{ width: '210px', height: '60px' }} />
                    </Navbar.Brand>
                </div>

                <div className='d-lg-flex container-fluid w-100  row justify-content-end'>
                    {keycloak.authenticated ? (
                        <div className="row  w-100 justify-content-end align-content-end">
                            <div className="col-auto d-flex align-items-center justify-content-end me-4">
                                <div className="col-sm-2 col me-2 justify-content-end align-items-end  rounded-circle"
                                    style={{ backgroundColor: "#000F8E", fontSize: "16px", fontWeight: "bold", height: "40px", width: "40px", textAlign: 'center' }} >
                                    <span style={{ color: "white", lineHeight: "40px" }}>{user !== null ? ExtractLetter(user.family_name) + ExtractLetter(user.given_name) : ''}</span>
                                </div>
                                <div className="col-auto">
                                    <div className="col-12" style={{ color: "#000F8E", fontSize: "16px", fontWeight: "bold", marginBottom: "4px" }}>
                                        <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                            {user != null && user.given_name ? firstLettreToMajuscule(user.given_name) + "   " : "undefined"} {user != null && user.family_name ? firstLettreToMajuscule(user.family_name) : "undefined"}</span>
                                    </div>
                                    <div className="col-12" style={{ fontSize: "14px", color: "#000F8E", whiteSpace: "nowrap" }}>
                                        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {user != null && user.company ? firstLettreToMajuscule(user.company) + "   " : "undefined"}</span>

                                    </div>
                                </div>
                            </div>

                            <div className="border-gradient-rounded m-3 col-auto pb-1 pt-1 pe-3  ps-3 align-items-center justify-content-center">
                                <Button className='logout log-button ps-3 pe-3 text-center' onClick={keycloak.logout}>
                                    <span className='log-title mt-2'>Log Out</span>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="border-gradient-rounded  col-auto pb-1 pt-1 pe-3  ps-3 justify-content-end align-content-end">
                            <Button className='logout log-button ps-3 pe-3 text-center' onClick={loginToKeycloak}>
                                <span className='log-title'>Login with wallet</span>
                            </Button>
                        </div>
                    )}
                </div>
            </Container>
        </Navbar>
    );
}

export default CustomNavbar;
