import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SearchBar from './search';
import Paginations from "./pagination";
import api from './services/api';
import { useKeycloak } from "@react-keycloak/web";
import jwt_decode from 'jwt-decode';
import CustomNavbar from './components/navbar';
import FilterLayerComponent from './components/filterComponent';
import LocationDropdownComponent from './components/locationDropdownComponent';
import ProviderDropdownComponent from './components/ProviderDropdownComponent';
import CertificationDropdownsComponent from './components/certificationsDropdownCompoment';
import earth from './assets/earth.png';
import provider_icon from './assets/provider-icon.png';
import CommonModal from './components/modal';
import whitestar from './assets/white-star.png';
import BackToTopButton from './components/backToTopButton';
import LabelComponent from "./components/LabelComponent";

import ChipList from "./components/ChipList";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

function App() {
    const [currentPage, setCurrentPage] = useState(0);
    const pageSize = 25;
    const [services, setServices] = useState();
    const [filters, setFilters] = useState(false)
    const [loading, setLoading] = useState(true);
    const {keycloak} = useKeycloak();


    const [type, setType] = useState("")
    const [layer, setLayer] = useState("")
    const [provider, setProvider] = useState("")
    const [ville, setVille] = useState("")
    const [pays, setPays] = useState("")
    const [certif, setCertif] = useState("")
    const [typecertif, setTypeCertif] = useState("")
    const [certifData, setCertifData] = useState()
    const [label, setLabel] = useState("")

    const [typeFilter, setTypeFilter] = useState()
    const [layerFilter, setLayerFilter] = useState()
    const [providerFilter, setProviderFilter] = useState([])
    const [labelFilter, setLabelFilter] = useState([])
    const [count, setCount] = useState(0)
    const [show, setShow] = useState(false);
    const [searchData, setSearchData] = useState(false)
    const [location,setLocation]=useState([])
    const [filterData,setFilterData]=useState([])
    const [searchValue,setSearchValue]=useState("")
    const [numbersFilter,setNumbersFilter]=useState(0);
    const [chipValues, setChipValues] = useState([]);
    const [providerValue, setProviderValue] = useState("");
    const [labelValue, setLabelValue] = useState("");
    const [download, setDownload] = useState(false);

    const [loadingData, setLoadingData] = React.useState(true);
    const [success, setSuccess] = React.useState(false);

    const buttonSx = {
        ...(success
                ? {
                    bgcolor: green[500],
                    '&:hover': {
                        bgcolor: green[700],
                    },
                }
                : {
                    bgcolor: '#000F8E',
                    '&:hover': {
                        bgcolor: '#475EF6',
                    },
                }
        ),
    };

    function CircularProgressWithLabel(
    ) {
        return (
            <Box
                sx={{
                    position: 'fixed',
                    bottom: '20px',
                    left: '20px',
                    display: 'inline-flex',
                    cursor: 'pointer',
                    zIndex: 1000,
                }}
            >
                <Box sx={{ m: 1, position: 'relative' }}>
                    <Fab
                        aria-label="save"
                        sx={buttonSx}
                        style={{ color:'white'}}
                    >
                        {success ? <CheckIcon /> : <SaveIcon />}
                    </Fab>
                    {loadingData && (
                        <CircularProgress
                            size={68}
                            sx={{
                                color: '#A931F6',
                                position: 'absolute',
                                top: -6,
                                left: -6,
                                zIndex: 1,
                            }}
                        />
                    )}
                </Box>

            </Box>
        );
    }

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }
    const resetFilter = () => {
        setCertif("");
        setTypeCertif("");
        setLabel("");
        setLayer("");
        setProvider("");
        setType("");
        setPays("");
        setVille("");
        setLabelValue("")
        setProviderValue("")
        setChipValues([]);
    }

    async function getDataService(currentPage,filter) {
        try {
            let getAllServices = await api.getServices(currentPage>=0 ?currentPage:0,pageSize,filter);
            getAllServices ? setServices(getAllServices.results) : setServices([]);
            getAllServices ? setCount(getAllServices.total) : setCount(0);
            await getService();
            setLoading(false);
        }catch (e) {
            setLoading(false);
            setServices([]);
            console.log(e)
        }
    }
    function transformData(jsonData) {
        return jsonData.reduce((result, item) => {
            const referenceType = item["aster-conformity:referenceType"] || "Other";
            if (!result[referenceType]) {
                result[referenceType] = [];
            }
            result[referenceType].push({
                id: item.id,
                name: item["aster-conformity:hasComplianceReferenceTitle"]
            });
            return result;
        }, {});
    }

    async function getService() {
        try {
            const dataFilter=await api.getFilterData();
            setLocation(dataFilter['available_addresses'] ? dataFilter['available_addresses'] :[]);
            setLayerFilter(dataFilter['available_layers'] ? dataFilter['available_layers'] :[]);
            setTypeFilter(dataFilter['available_services'] ? dataFilter['available_services'] :[]);
            setLabelFilter(dataFilter['available_aster_labels'] ? dataFilter['available_aster_labels'] : []);
            setProviderFilter(dataFilter['available_providers'] ? dataFilter['available_providers'] : []);
            setCertifData(transformData(dataFilter['available_compliance_references'] ? dataFilter['available_compliance_references']: []));
            setLoading(false);
        }catch (e) {
            setLoading(false);
            console.log(e)
        }
    }

    useEffect(() => {
        if (keycloak.authenticated) {
            const decodedVP = jwt_decode(keycloak.token);
            localStorage.setItem("decodedVP", JSON.stringify(decodedVP));
        }
    }, [keycloak.authenticated, keycloak.token]);

    useEffect(() => {
        getDataService(currentPage,filterData);
    }, [currentPage,filterData]);

    function selection(e, className) {
        const element = document.querySelector("." + className)
        if (element) {
            element.classList.remove(className)
            e.currentTarget.classList.toggle(className)
        } else {
            e.currentTarget.classList.toggle(className)
        }

    }

    async function handleLabel(id,valueLabel,e) {
        setLabel(id)
        setLabelValue(valueLabel)
        selection(e, "selec-label")
    }

    function getIdFilterCertif(certifType, certifValue) {
        let filter = [];
    
        if (certifType && certifType in certifData) {
            filter = certifData[certifType]?.filter(datacertif => 
                certifValue === "" || datacertif.name === certifValue
            ).map(datacertif => datacertif.id);
        } else if (certifValue) {
            Object.values(certifData).forEach(items => {
                items.filter(item => item.name === certifValue)
                     .forEach(item => filter.push(item.id));
            });
        }
    
        return filter;
    }

    async function handleCertif(e) {
        if(typeof e !== 'object'){
            setCertif(e)
        }
    }
    async function handletypeCertif(e) {
        if(typeof e !== 'object'){
            setTypeCertif(e)
        }
    }

    async function handlePaysChange(e) {
        setPays(e)
    }

    async function handleVilleChange(e) {
        e !== "All cities" ? setVille(e) : setVille("");
    }

    async function handleProvider(providerFilter,valueProvider) {
        setProvider(providerFilter)
        setProviderValue(valueProvider)
    }

    async function handleLayer(e) {
        setLayer(e.target.value)
        selection(e, "selec-layer")
    }

    async function handleTypes(e) {
        setType(e.target.value)
        selection(e, "selec-type")
    }

    async function handleDownloadList() {
        setDownload(true);
        setLoadingData(true);
        const jsonData = JSON.stringify(await api.getServices(0, count, filterData));
        const url = URL.createObjectURL(new Blob([jsonData], {type: 'application/json'}));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'all services.json';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setSuccess(true);
        setLoadingData(false);
        window.setTimeout(() => {
            setSuccess(false);
            setDownload(false)
        }, 2000);

    }

    function countNonEmptyValues(object) {
        let count = 0;
        for (const key in object) {
            if (Array.isArray(object[key]) && object[key].length > 0) {
                count++;
            }
        }
        return count;
    }

    async function addFilter(e) {
        const data = {
            "provider_ids": provider !== "" ? [provider] : [],
            "keywords": type !== "" ? [type] : [],
            "countries": pays !== "" ? [pays] : [],
            "urban_areas": ville !== "" ? [ville] : [],
            "layers": layer !== "" ? [layer] : [],
            "compliance_ref_ids":  getIdFilterCertif(typecertif,certif),
            "aster_label_ids": label !== "" ? [label] : [],
            "search_service_name": searchValue
        }
        const inputArray=[{"label":label !== "" ? labelValue : ""}, {"typecertif": typecertif}, {"certif": certif}, {"type": type}, {"layer": layer}, {"pays": pays}, {"ville": ville}, {"provider": provider !== "" ? providerValue: ""}]
        const filteredArray = inputArray.filter(item => Object.values(item)[0] !== "");
        setChipValues(filteredArray);
        const numberOfFilter=countNonEmptyValues(data);
        setNumbersFilter(certif !== "" ? numberOfFilter+1: numberOfFilter)
        setFilterData(data)
        setCurrentPage(0)
        setFilters(true)
        setLoading(true)
        setShow(false);
    }
    async function closeHandleBtn() {
        resetFilter()
        setFilterData({
            "provider_ids":  [],
            "keywords": [],
            "countries": [],
            "urban_areas":  [],
            "layers":  [],
            "compliance_ref_ids": [],
            "aster_label_ids":  [],
            "search_service_name": searchValue
        });
        setNumbersFilter(0)
        setFilters(false)
        setLoading(true);

    }
    async function handleValueChange(data) {
        setFilterData({
            "provider_ids": provider !== "" ? [provider] : [],
            "keywords": type !== "" ? [type] : [],
            "countries": pays !== "" ? [pays] : [],
            "urban_areas": ville !== "" ? [ville] : [],
            "layers": layer !== "" ? [layer] : [],
            "compliance_ref_ids":  getIdFilterCertif(typecertif,certif),
            "aster_label_ids": label !== "" ? [label] : [],
            "search_service_name": data
        });
        setSearchValue(data);
        setFilters(false);
        setSearchData(true);
        setLoading(true);
    }

    function handleLoad() {
        setLoading(false);
    }
    function handleChange(pageData) {
        setCurrentPage(pageData);
    }

    const handleDelete = (index) => {
        const filterDelete=chipValues[index];
        const newChipValues = [...chipValues];
        newChipValues.splice(index, 1);
        setChipValues(newChipValues);
        const dataFiltre={
            "provider_ids": provider !== "" ? [provider] : [],
            "keywords":  type !== "" ? [type] : [],
            "countries": pays !== "" ? [pays] : [],
            "urban_areas": ville !== "" ? [ville] : [],
            "layers":  layer !== "" ? [layer] : [],
            "compliance_ref_ids": getIdFilterCertif(typecertif,certif),
            "aster_label_ids": label !== "" ? [label] : [],
            "search_service_name": searchValue?searchValue: ""
        };
        if (filterDelete.type) {
           setType("")
            dataFiltre.keywords= [];
        }else if (filterDelete.label) {
          setLabel("")
            dataFiltre.aster_label_ids= []
        }else if (filterDelete.typecertif) {
           setTypeCertif("")
            dataFiltre.compliance_ref_ids=  getIdFilterCertif("",certif)
                    }else if (filterDelete.certif) {
            setCertif("")
            dataFiltre.compliance_ref_ids=  getIdFilterCertif(typecertif,"")
                    }else if (filterDelete.layer) {
            setLayer("");
            dataFiltre.layers= []
        }else if (filterDelete.pays) {
           setPays("")
            dataFiltre.countries= []
        }else if (filterDelete.ville) {
            setVille("")
            dataFiltre.urban_areas= []
        }else if (filterDelete.provider) {
            setProvider("");
            dataFiltre.provider_ids= []
        }
        dataFiltre.search_service_name= searchValue?searchValue:"";
        setFilterData(dataFiltre);
        const numberFilters=countNonEmptyValues(dataFiltre);
        (filterDelete.typecertif || filterDelete.certif) ? setNumbersFilter(numberFilters) : setNumbersFilter(certif !== "" ? numberFilters+1 : numberFilters)

    };

    return (
        <div className='container-fluid'>
            <CustomNavbar keycloak={keycloak} />
            <div className="row company-search align-items-center py-2 py-lg-0 gap-lg-0 gap-3">
                <div className="col-lg-8 d-flex justify-content-start">
                    <svg className="bag d-none d-lg-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z" /></svg>
                    <div>
                        <p className='p-banner pb-2'>This site is for DEMONSTRATION purposes and technical evaluation of the latest Gaia-X specification v22.10.</p>
                        <p className='p-banner fw-light'>All the Verifiable Credentials and Presentations are SIMULATED and are not binding for any organizations including the Gaia-X association and any of the participant to this site design.</p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <SearchBar  handleValueChange={handleValueChange}/>
                </div>
            </div>

            <CommonModal type="disclaimer" />
            { download ? <CircularProgressWithLabel /> :"" }
            <div className=" row justify-content-sm-between mt-5">
                <div className="h-100 col-12 col-sm-10 col-md-8 col-xl-7 col-xxl-9">
                    {loading ? "" :
                        <p className="p-results h-100">
                            {count} results
                            {count >0 ?<span className='download-list'>
                            &nbsp; | &nbsp;
                                <span className='download' onClick={handleDownloadList}>
                            <svg className='download-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z" fill="#000F8E"></path> <path d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z" fill="#000F8E"></path></svg>
                                    &nbsp;
                                    <span className='download-text'>Download the results of all services</span>
                            </span>
                        </span>:""}
                        </p>
                    }
                </div>
                <div className="border-gradient-rounded me-4 ms-4 aligh-items-center col-6 col-sm-3 col-md-3 col-xl-2 col-xxl-2">
                    <Button onClick={handleShow} className=" px-auto btn-filter"><svg className='filter-logo' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" /></svg>
                        Filters <span style={{ backgroundColor: "#A931F6", padding: "3px 7px", borderRadius: "10px", color: "white" }}>
  {numbersFilter}
</span>

                    </Button>
                </div>
            </div>
            {
                numbersFilter>0?
            <div className="row mt-2 " style={{backgroundColor:"white",height:"50%"}}>
                <div className="col-10 mt-2">
                    <div className="container-fluid d-flex align-items-start justify-content-start">
                        <div className="row">
                            <div className="col m-2">
                                <ChipList values={chipValues} onDelete={handleDelete} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-auto d-flex align-items-end justify-content-end' style={{margin:"auto"}}>

                    <Button onClick={closeHandleBtn} variant="text" style={{ textDecoration:"underline",color:"#000F8E", fontSize:"18px" }}>Reset all</Button>
                </div>

            </div>:""
            }
            <div className="section-filters mt-4 mt-md-0">
                <div className='filter-show'>
                    <div className='container-btn-filter none'>
                        <Button value='Italy' href='#' className="me-2 btn-filter keyword">
                            <svg className='book-logo' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" /></svg>Keyword</Button>
                    </div>
                    <div>
                        <Offcanvas className="filter-section" show={show} onHide={handleClose} placement={"end"}>
                            <Offcanvas.Header closeButton={false} className="filter-close-button">
                                <button type="button" className="btn-close btn-close-filter ms-0" onClick={handleClose}></button>
                                <Offcanvas.Title className='filter-title'>Filters</Offcanvas.Title>
                                <Button className='reset-filter' onClick={closeHandleBtn}>Reset all</Button>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className='section-filter'>
                                    <div className='d-flex justify-content-start'>
                                        <img alt='white star' width='20px' height='20px' src={whitestar}></img>
                                        <p className='filter-label ms-2'>Aster X label</p>
                                    </div>
                                    <div>
                                        <LabelComponent handleLabelClick={handleLabel} labels={labelFilter} />
                                    </div>
                                </div>

                                <FilterLayerComponent
                                    loading={false}
                                    title="Certification">
                                    <CertificationDropdownsComponent handleCertif={handleCertif} handletypeCertif ={handletypeCertif}  CertifData={certifData} />
                                </FilterLayerComponent>

                                <FilterLayerComponent loading={loading} filterData={typeFilter} handleFilter={handleTypes} title="Type of Service" />

                                <FilterLayerComponent loading={loading} filterData={layerFilter} handleFilter={handleLayer} title="Layer" />

                                <div className='section-filter filter-label'>
                                    <p><img alt='earth icon' src={earth} width='20px' height='20px' className='me-2'></img>Location</p>
                                    <div>
                                        <LocationDropdownComponent handlePaysChange={handlePaysChange} handleVilleChange={handleVilleChange} location={location}/>
                                    </div>
                                </div>

                                <div className='section-filter filter-label'>
                                    <p><img alt='provider icon' src={provider_icon} width='20px' height='20px' className='me-2'></img>Provider</p>
                                    <div>
                                        <ProviderDropdownComponent
                                            filterData={providerFilter}
                                            handleProviderChange={handleProvider}
                                        />
                                    </div>
                                </div>
                                <div className='center-btn'>
                                    <Button className='btn-submit-filters px-5' onClick={addFilter}>Apply filters</Button>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="loader-container" >
                    <div className="spinner"></div>
                </div>) : (<Paginations services={services} reset={filters} searchData={searchData} handleLoad={handleLoad} handleChange={handleChange} page={currentPage} count={count} pageSize={pageSize}/>)
            }
            <BackToTopButton />
        </div>
    );
}

export default App;
