// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-tabs .nav-item .nav-link {
    border: 0;
    color: #000F8E;
    background-color: white;
}
  
.nav-tabs .nav-item .nav-link.active {
    border: 0;
    border-radius: 0;
    color: #00B4D5;
    border-color: #00B4D5;
    background-color: white;
    border-bottom: 2px solid #00B4D5;
}

.active:hover {
    color: #00B4D5 !important;
}


/* Hide scrollbar for Chrome, Safari and Opera */
/*
.example::-webkit-scrollbar {
    display: none;
  }
  */
  /* Hide scrollbar for IE, Edge and Firefox */
  .example {
    overflow: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  } 



.distriContainer {
    display: flex;
    flex-wrap: wrap;
}
  
.distriLeft {
    flex: 1 1;
}
  
.distriRight {
    flex: 1 1;
}
  
@media only screen and (max-width: 1100px) {
    .distriLeft {
        flex-basis: 100%;
    }
  
    .distriRight {
        flex-basis: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/distribution.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,SAAS;IACT,gBAAgB;IAChB,cAAc;IACd,qBAAqB;IACrB,uBAAuB;IACvB,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA,gDAAgD;AAChD;;;;GAIG;EACD,4CAA4C;EAC5C;IACE,cAAc;IACd,kBAAkB;IAClB,wBAAwB,GAAG,gBAAgB;IAC3C,qBAAqB,GAAG,YAAY;EACtC;;;;AAIF;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,SAAO;AACX;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".nav-tabs .nav-item .nav-link {\n    border: 0;\n    color: #000F8E;\n    background-color: white;\n}\n  \n.nav-tabs .nav-item .nav-link.active {\n    border: 0;\n    border-radius: 0;\n    color: #00B4D5;\n    border-color: #00B4D5;\n    background-color: white;\n    border-bottom: 2px solid #00B4D5;\n}\n\n.active:hover {\n    color: #00B4D5 !important;\n}\n\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n/*\n.example::-webkit-scrollbar {\n    display: none;\n  }\n  */\n  /* Hide scrollbar for IE, Edge and Firefox */\n  .example {\n    overflow: auto;\n    overflow-y: hidden;\n    -ms-overflow-style: none;  /* IE and Edge */\n    scrollbar-width: none;  /* Firefox */\n  } \n\n\n\n.distriContainer {\n    display: flex;\n    flex-wrap: wrap;\n}\n  \n.distriLeft {\n    flex: 1;\n}\n  \n.distriRight {\n    flex: 1;\n}\n  \n@media only screen and (max-width: 1100px) {\n    .distriLeft {\n        flex-basis: 100%;\n    }\n  \n    .distriRight {\n        flex-basis: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
