
import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';


const ProviderDropdownComponent = ({ handleProviderChange, filterData }) => {

    const handleProvider = (event, value) => {
        if (value && (filterData !== undefined)) {
            const Provider= filterData.find(provider => provider.credentialSubject && value === provider.credentialSubject['gx:legalName']);
    if(Provider) {
    handleProviderChange(Provider['id'] || Provider['@id'],value);
     }
        }
    }
    return (
        <>
            <Stack spacing={2} sx={{ width: 300, marginTop: '20px', display: 'inline' }}>
                <Autocomplete
                    id="provider"
                    onChange={handleProvider}
                    options={(filterData !== undefined)
                        ? filterData
                            .filter(provider => provider.credentialSubject && provider.credentialSubject['gx:legalName'] !== undefined)
                            .map(provider => provider.credentialSubject['gx:legalName'])
                        : []
                    }
                    filterOptions={(options, { inputValue }) =>
                        options.filter(option => {
                            const regex = new RegExp(`^${inputValue}`, 'i');
                            return regex.test(option);
                        })
                    }
                    renderInput={(params) => <TextField {...params} label="Provider" />}
                />
            </Stack>
        </>
    );
};


export default ProviderDropdownComponent;
