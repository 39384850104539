// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item {
    align-items: center;
    color: #808e9b;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
    border: solid 1px #808e9b;
    border-radius: 40px;
    margin: 5px;
  }
  
  .disabled-page {
    color: #808e9b;
  }
  
  .active-pagination {
    border: solid 1px #000F8E;
    background-color: #000F8E;
    border-radius: 40px;
    color: white;
    cursor: pointer;
  }
  
  .break-me {
    border: 0;
    border-radius: 0;
  }
  
  .next {
    font-size: 20px;
  }

  .previous {
    font-size: 20px;
  }

  .arrow-svg {
    width: 10px;
    padding-bottom: 5px;
    fill: #808e9b;
  }
  
  .pagination {
    left: 35%;
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 30%;
  }
  
  .pagination-page {
    font-weight: 700;
  }
  
 `, "",{"version":3,"sources":["webpack://./src/pagination.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,aAAa;IACb,eAAe;IACf,YAAY;IACZ,uBAAuB;IACvB,WAAW;IACX,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,SAAS;IACT,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,qBAAqB;IACrB,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".item {\n    align-items: center;\n    color: #808e9b;\n    cursor: pointer;\n    display: flex;\n    font-size: 14px;\n    height: 40px;\n    justify-content: center;\n    width: 40px;\n    border: solid 1px #808e9b;\n    border-radius: 40px;\n    margin: 5px;\n  }\n  \n  .disabled-page {\n    color: #808e9b;\n  }\n  \n  .active-pagination {\n    border: solid 1px #000F8E;\n    background-color: #000F8E;\n    border-radius: 40px;\n    color: white;\n    cursor: pointer;\n  }\n  \n  .break-me {\n    border: 0;\n    border-radius: 0;\n  }\n  \n  .next {\n    font-size: 20px;\n  }\n\n  .previous {\n    font-size: 20px;\n  }\n\n  .arrow-svg {\n    width: 10px;\n    padding-bottom: 5px;\n    fill: #808e9b;\n  }\n  \n  .pagination {\n    left: 35%;\n    align-items: center;\n    display: flex;\n    flex-direction: row;\n    height: 60px;\n    justify-content: center;\n    list-style-type: none;\n    position: relative;\n    width: 30%;\n  }\n  \n  .pagination-page {\n    font-weight: 700;\n  }\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
