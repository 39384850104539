import React, { useState, useEffect } from "react";
import SingleServices from "./singleService";
import './pagination.css'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const Paginations = ({ services,reset,handleLoad,searchData,handleChange,page,count,pageSize}) => {
  const [currentPage, setCurrentPage] = useState(page>=0 ? page+1 : 1);
  const currentServices = services ? services : [];
  const pageCount = services ? Math.ceil(count / pageSize): 1;

  const handlePageClick = (event, page) => {
    setCurrentPage(page);
    handleChange(page-1)
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (reset || searchData) {
      setCurrentPage(1);
      handleChange(0)
      handleLoad();
    }
    if (currentPage > pageCount && pageCount > 0) {
      setCurrentPage(pageCount);
      handleChange(pageCount-1);
    } else{
      setCurrentPage(currentPage);
      handleChange(currentPage-1);
    }
  }, [currentPage])

  return (
    <>
    {currentServices.map((service, index) => {
        return <SingleServices key={index} service={service}/>
    })}
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center",marginBottom:'15px'}}>
        <Stack sx={{mt:'20px'}}>
          <Pagination  siblingCount={0} sx={{
            '& .MuiPaginationItem-page.Mui-selected': {
              color: 'white',
              backgroundColor:'#000F8E'
            },
            '& .MuiPaginationItem-page': {
              color: '#000F8E',
            }}} variant="outlined" count={pageCount} shape="circular" page={currentPage} onChange={handlePageClick}/>
        </Stack>
      </div>
    </>
  );
};

export default Paginations;