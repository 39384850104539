import axios from 'axios';
const negotiationTool = {
    decodeUser: () => {
        return JSON.parse( localStorage.getItem("decodedVP"));
    },

    buildPayload: (vc) => {
        let policy;
        let provider;
        let serviceType;
        let serviceOffering;
        let termsAndConditions;
        const consumer = negotiationTool.decodeUser();

        vc.forEach((credential) => {
            const subject = credential.credentialSubject;
            if (subject && (subject['type'] || subject['@type'])) {
                switch (subject['type'] || subject['@type']) {
                    case 'gx:ServiceOffering':
                        serviceOffering = credential;
                        serviceType = subject["gx:keyword"] ? subject["gx:keyword"] : "Unknown";
                        termsAndConditions = typeof subject['gx:termsAndConditions'] === 'string' ? subject['gx:termsAndConditions'] : subject['gx:termsAndConditions']["gx:URL"]
                        policy = subject["gx:policy"] ? subject["gx:policy"] : "Unknown"
                        break;
                    case 'gx:LegalParticipant':
                        provider = subject['gx:legalName'];
                        break;
                    default:
                        break;
                }
            }
        });


        let payload = {
            "Consumer": {
                "firstName": consumer.given_name,
                "lastName": consumer.family_name,
                "email": consumer.email,
                "username": consumer.preferred_username
            },
            "Provider": {
                "firstName": provider,
                "lastName": provider,
                "email": "provider.agdatahub@proton.me",
                "username": "did:key:z6mkgorxqahtuzkz1amoyvfhqma7tgofncttllrpum6psj3f"
            },
            "Data": {
                "gx:consumedBy": consumer.preferred_username,
                "gx:termsAndConditions": termsAndConditions,
                "gx:policies": policy,
                "gx:serviceType": serviceType,
                "gx:providedBy": provider
            },
            "VerifiableCredential": serviceOffering
        }

        return payload;
    },

    initiateNegotiation: async (vc) => {
        try {
            const payload = negotiationTool.buildPayload(vc);
            const result = await axios.post(`${process.env.REACT_APP_NEGOCATION_TOOL_API}/negotiate`, payload);
            if (result.status === 200) {
                window.location.href = result.data.shareUrl;
                return { success: true };
            } else {
                console.error("Error initiating negotiation:", result.status);
                return { success: false };
            }
        } catch (error) {
            console.error("Error initiating negotiation:", error);
            return { success: false, error };
        }
    },

    initiateSubcription: async (vc) => {
        try {
            const payload = negotiationTool.buildPayload(vc);
            const result = await axios.post(`${process.env.REACT_APP_NEGOCATION_TOOL_API}/subscribe`, payload);
            if (result.status === 200) {
                return { success: true };
            } else {
                console.error("Error initiating subcription:", result.status);
                return { success: false };
            }
        } catch (error) {
            console.error("Error initiating subcription:", error);
            return { success: false, error };
        }
    }
};

export default negotiationTool;