import React, { useState } from 'react';
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const CertificationDropdownsComponent = ({ handletypeCertif, handleCertif, CertifData }) => {

    const [selectedCertif, setSelectedCertif] = useState(null);

    const typeCertifChange = (event, value) => {
        if (value) {
            setSelectedCertif(null)
            handletypeCertif(value);
        }
    };

    const CertifChange = (event, value) => {
        if (value) {
            handleCertif(value);
            setSelectedCertif(value)
        }
    };

    const [selectedKey, setSelectedKey] = useState("");

    return (
        <>
            <Stack spacing={2} sx={{ width: 300, marginTop: '20px', display: 'inline' }}>
                <Autocomplete
                    id="typecertif"
                    value={selectedKey}
                    onChange={(event, newValue) => {
                        setSelectedKey(newValue);
                        typeCertifChange(event, newValue);
                    }}
                    options={CertifData !== undefined ? Object.keys(CertifData) : {}}
                    filterOptions={(options, { inputValue }) =>
                        options.filter(option => {
                            const regex = new RegExp(`^${inputValue}`, 'i');
                            return regex.test(option);
                        })
                    }
                    renderInput={(params) => <TextField {...params} label="Type de certification" />}
                />
                <Autocomplete
                    onChange={CertifChange}
                    id="certif"
                    value={selectedCertif}
                    options={(CertifData && CertifData[selectedKey]) !== undefined ? Array.from(new Set(CertifData[selectedKey].map((option) => option.name))) : []}
                    filterOptions={(options, { inputValue }) =>
                        options.filter(option => {
                            const regex = new RegExp(`^${inputValue}`, 'i');
                            return regex.test(option);
                        })
                    }
                    renderInput={(params) => <TextField {...params} label="Certification" />}
                />
            </Stack>
        </>
    );
};
export default CertificationDropdownsComponent;