import { Button } from 'react-bootstrap'
import './label.ref.css'
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios'
import { useKeycloak } from "@react-keycloak/web";

import { RedCircleSVG, GreenCircleSVG } from './components/SVGComponent'; // Assuming you've moved them to a separate file.
import CustomNavbar from './components/navbar';

function Labelling() {
    const { keycloak } = useKeycloak();
    const [vpCriteria, setVpCriteria] = useState([])
    const [criteria, setCriteria] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState('Level 1');
    const [totalCriteria, setTotalCriteria] = useState(0);
    const [validatedCriteria, setValidatedCriteria] = useState(0);

    const { vp_did } = useParams();
    async function checkVP() {
        if (vp_did === undefined) {
            return; // make sure to exit the function if there's an error
        }
        const did_vp = vp_did.replaceAll(":", "/").replace("did/web/", "https://");
        await axios.get(did_vp).then(async (result) => {
            let criteriaFromVP = []
            criteriaFromVP = criteriaFromVP.concat(extractPropertiesFromVP(result.data, ["aster-conformity:hasAssessedComplianceCriteria"]));
            const criteriaFromClaims = await extractCriteriaFromClaims(result.data);
            criteriaFromVP = criteriaFromVP.concat(criteriaFromClaims);
            setVpCriteria(criteriaFromVP);
        }).catch((error) => {
            console.log('error', {});
        })
    }

    async function extractCriteriaFromClaims(vp) {
        const claims = extractPropertiesFromVP(vp, ["aster-conformity:hasComplianceCertificationScheme"]);
        let res = [];
        for (let claim of claims) {
            const did = claim.replaceAll(":", "/").replace("did/web/", "https://");
            await axios.get(did).then((result) => {
                result.data["aster-conformity:grantsComplianceCriteria"].forEach(criteria => {
                    res.push(criteria.id);
                })
            }).catch((error) => {
                console.log('Error ', error);
            })
        }
        return res;
    }

    function extractPropertiesFromVP(vp, keysToFind) {
        function findProperties(obj, keys) {
            let results = [];
            for (let key of Object.keys(obj)) {
                if (obj[key] !== undefined && obj[key] !== null) {
                    if (keys.includes(key)) {
                        if (Array.isArray(obj[key])) {
                            obj[key].forEach(prop => {
                                if (prop && prop.id !== undefined) {
                                    results.push(prop.id);
                                }
                            });
                        } else if (obj[key].id !== undefined) { // Si c'est un objet simple
                            results.push(obj[key].id);
                        }
                    }
                }
                if (typeof obj[key] === "object" && obj[key] !== null) {
                    results = results.concat(findProperties(obj[key], keys));
                }
            }
            return results;
        }
        return findProperties(vp, keysToFind);
    }

    // To get all criteria
    async function getCriteria() {
        try {
            const response = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_CATALOG_API}/api/compliance_criterions`
            });

            const groupedByCategory = {};

            if (response.data) {
                response.data.forEach(vc => {
                    const formattedVC = {
                        title: vc["aster-conformity:hasDescription"],
                        id: vc["id"],
                        level: vc["aster-conformity:hasLevel"],
                        criterionNumber: vc["aster-conformity:hasName"]
                    };
                    const category = vc["aster-conformity:hasCategory"];

                    if (!groupedByCategory[category]) {
                        groupedByCategory[category] = [];
                    }

                    groupedByCategory[category].push(formattedVC);
                });
                setCriteria(groupedByCategory);
            } else {
                console.log({});
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const CriteriaTable = ({ title, criteria, vpCritera }) => {
        const totalCriteria = criteria.length;
        const countMetCriteria = criteria.filter(criterion => vpCritera.includes(criterion.id)).length;

        return (
            <>
                <tr className='criterion'>
                    <th className='bold' scope="row">{title}</th>
                    <th scope="row"></th>
                    <th scope="row"></th>
                    <th scope="row"></th>
                    <th scope="row">{countMetCriteria}/{totalCriteria} </th>
                </tr>
                {criteria.map((criterion, idx) => (
                    <tr key={idx}>
                        <td>{criterion.criterionNumber} {criterion.title}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{(vpCritera && vpCritera.includes(criterion.id)) ? <GreenCircleSVG /> : <RedCircleSVG />}</td>
                    </tr>
                ))}
            </>
        );
    };

    const filterCriteriaByLevel = (criteria) => {
        return Object.entries(criteria)
            .map(([category, criteriaItems]) => {
                const filteredCriteriaItems = criteriaItems.filter(item => item.level === selectedLevel);

                if (filteredCriteriaItems.length === 0) return null;

                filteredCriteriaItems.sort((a, b) => {
                    const [aMain, aSub, aSubSub] = a.criterionNumber.split(' ').pop().split('.').map(Number);
                    const [bMain, bSub, bSubSub] = b.criterionNumber.split(' ').pop().split('.').map(Number);
                    return aMain - bMain || aSub - bSub || aSubSub - bSubSub;
                });

                return [category, filteredCriteriaItems];
            })
            .filter(category => category !== null)
            .sort(([, aCriteriaItems], [, bCriteriaItems]) => compareCriteria(aCriteriaItems[0], bCriteriaItems[0]));
    }


    const compareCriteria = (a, b) => {
        const [aMain, aSub, aSubSub] = a.criterionNumber.split(' ').pop().split('.').map(Number);
        const [bMain, bSub, bSubSub] = b.criterionNumber.split(' ').pop().split('.').map(Number);
        return aMain - bMain || aSub - bSub || aSubSub - bSubSub;
    }


    useEffect(() => {
        checkVP();
        getCriteria();
    }, []);

    useEffect(() => {
        if (criteria && vpCriteria) {
            const allCriteriaForLevel = Object.values(criteria)
                .flat()
                .filter(criterion => criterion.level === selectedLevel);

            const totalForLevel = allCriteriaForLevel.length;
            const validatedForLevel = allCriteriaForLevel.filter(criterion => vpCriteria.includes(criterion.id)).length;

            setTotalCriteria(totalForLevel);
            setValidatedCriteria(validatedForLevel);
        }
    }, [criteria, vpCriteria, selectedLevel]);

    return (
        <div>
            <CustomNavbar keycloak={keycloak} />
            <div className='container-btn-label'>
                <div className='container-btn'><Button className='btn-lab' onClick={() => setSelectedLevel('Level 1')}>Level 1</Button></div>
                <div className='container-btn'><Button className='btn-lab' onClick={() => setSelectedLevel('Level 2')}>Level 2</Button></div>
                <div className='container-btn'><Button className='btn-lab' onClick={() => setSelectedLevel('Level 3')}>Level 3</Button></div>
            </div>
            <div className="table-wrapper-scroll-y my-custom-scrollbar">
                <table className="table table-bordered table-striped mb-0">
                    <thead>
                    <tr className='blue'>
                        <th className='labelling' scope="col" colSpan={4}>
                            <svg className='start' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                            </svg>
                            {selectedLevel}
                        </th>
                        <th scope="col">
                            {validatedCriteria}/{totalCriteria}
                        </th>
                    </tr>

                    </thead>
                    <tbody>
                    {criteria && filterCriteriaByLevel(criteria)
                        .map(([category, filteredCriteriaItems], idx) => (
                            <CriteriaTable key={idx} title={category} criteria={filteredCriteriaItems} vpCritera={vpCriteria} />
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Labelling