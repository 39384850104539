import React, { useState, useEffect } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';

function CommonModal({ type }) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (type === "disclaimer") {
            const hasAcceptedDisclaimer = localStorage.getItem("hasAcceptedDisclaimer");
            if (!hasAcceptedDisclaimer)
                setShow(true);
        }
        else {
            setShow(true)
        }
    }, []);

    const handleClose = () => {
        type === "disclaimer" && localStorage.setItem("hasAcceptedDisclaimer", "true");
        setShow(false);
    };

    return (
        <Modal className="custom-modal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <Alert variant="sucess">
                    <Alert.Heading>{type === "disclaimer" ? "Disclaimer" : "Error"}</Alert.Heading>
                    <p>
                        {
                            type === "disclaimer"
                                ? (
                                    <>
                                        This site is for demonstration purposes and technical evaluation of Gaia-X specifications for the catalog implementation. It aims to implement GAIA-X Trust Framework v23.11 on a service catalog. All the Verifiable Credentials and Presentations in the backend are simulated and are not binding for any organizations including GAIA-X AISBL and any of the participants to this site design. Therefore content on this site is provided without any warranty and can be removed without any notice at any time. By using this site the visitor acknowledges that he understands and agrees with the herebefore statements.
                                        If you have any questions about this site, please
                                        <a className='contact' href='https://gaia-x.eu/contact/'>contact Gaia-X</a> or visit the
                                        <a className='contact' href='https://gitlab.com/gaia-x/data-infrastructure-federation-services/deployment-scenario/common-federation-service-catalogue'>Common Federation Service Catalogue on GitLab</a>.
                                    </>
                                )
                                : (
                                    "An error occurred when trying to subscribe to this service. Please try again later or contact the service provider."
                                )
                        }

                    </p>
                    <div className="d-flex justify-content-end">
                        <Button className="w-100" onClick={handleClose} variant="outline-success">
                            Understood
                        </Button>
                    </div>
                </Alert>
            </Modal.Body>
        </Modal>
    );
}

export default CommonModal;
