import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from "./services/keycloak";
import Labelling from './labelling';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={"check-sso"}>
    <BrowserRouter>
      <Routes>
        <Route path="/catalog" element={<App />}>
        </Route>
        <Route path="/" element={<Navigate to="/catalog" replace />} />
        <Route path="/labelling/:vp_did" element={<Labelling />}>
        </Route>
      </Routes>
    </BrowserRouter>
  </ReactKeycloakProvider>
);

reportWebVitals();
