// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles pour les écrans de petite taille (par exemple, les smartphones) */
/* @media screen and (max-width: 390px) {
    .type-service {
        min-width: 100% !important;
    }
}

@media screen and (min-width: 578px) {
    .btn-close-filter {
        display: none;
    }
} */

@media screen and (max-width: 768px) {
    .label-title {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 425px) {
    .btn-close {
        margin-left: 40px;
    }
}

@media screen and (min-width: 362px) and (max-width: 577px)  {

}

@media screen and (min-width: 578px) and (max-width: 768px)  {
}

/* Styles pour les écrans de taille moyenne (par exemple, les tablettes) */
@media screen and (min-width: 769px) and (max-width: 1024px) {

}

/* Styles pour les écrans de grande taille (par exemple, les ordinateurs de bureau) */
@media screen and (min-width: 1025px) {

}
`, "",{"version":3,"sources":["webpack://./src/media-query.css"],"names":[],"mappings":"AAAA,2EAA2E;AAC3E;;;;;;;;;;GAUG;;AAEH;IACI;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;;AAEA;;AAEA;AACA;;AAEA,0EAA0E;AAC1E;;AAEA;;AAEA,qFAAqF;AACrF;;AAEA","sourcesContent":["/* Styles pour les écrans de petite taille (par exemple, les smartphones) */\n/* @media screen and (max-width: 390px) {\n    .type-service {\n        min-width: 100% !important;\n    }\n}\n\n@media screen and (min-width: 578px) {\n    .btn-close-filter {\n        display: none;\n    }\n} */\n\n@media screen and (max-width: 768px) {\n    .label-title {\n        font-size: 12px !important;\n    }\n}\n\n@media screen and (max-width: 425px) {\n    .btn-close {\n        margin-left: 40px;\n    }\n}\n\n@media screen and (min-width: 362px) and (max-width: 577px)  {\n\n}\n\n@media screen and (min-width: 578px) and (max-width: 768px)  {\n}\n\n/* Styles pour les écrans de taille moyenne (par exemple, les tablettes) */\n@media screen and (min-width: 769px) and (max-width: 1024px) {\n\n}\n\n/* Styles pour les écrans de grande taille (par exemple, les ordinateurs de bureau) */\n@media screen and (min-width: 1025px) {\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
