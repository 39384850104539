import React from 'react';
import Chip from '@mui/material/Chip';

import CancelIcon from '@mui/icons-material/Cancel';
const ChipList = ({ values, onDelete }) => {
    return (
        <>
            {values.map((value, index) => (
                <Chip
                    key={index}
                    label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {Object.keys(value)[0] === 'label' && (
                                <div className="d-flex" style={{ textAlign: 'center',  marginTop: '4px'}}>
                                    {value['label'] === 'level 3' && (
                                        <>
                                            <svg style={{ width: 40, height: 25 ,marginRight: '0px', marginLeft: '-15px'}} className='stars-btn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                <path  fill="white" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                            </svg>
                                            <svg style={{ width: 40, height: 25 , marginRight: '0px', marginLeft: '-15px'}} className='stars-btn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                <path  fill="white" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                            </svg>
                                            <svg style={{ width: 40, height: 25 , marginRight: '0px', marginLeft: '-15px'}} className='stars-btn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                <path  fill="white" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                            </svg>
                                        </>
                                    )}
                                    {value['label'] === 'level 2' && (
                                        <>
                                            <svg style={{ width: 40, height: 25,marginRight: '0px', marginLeft: '-15px' }} className='stars-btn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                <path  fill="white" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                            </svg>
                                            <svg style={{ width: 40, height: 25 ,marginRight: '0px', marginLeft: '-15px'}} className='stars-btn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                <path  fill="white" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                            </svg>
                                        </>
                                    )}
                                    {value['label'] === 'level 1' && (
                                        <svg style={{ width: 40, height: 25,marginRight: '0px', marginLeft: '-15px' }} className='stars-btn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path  fill="white" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                                        </svg>
                                    )}
                                </div>
                            )}
                            <span>{Object.values(value)[0]}</span>
                        </div>
                    }
                    onDelete={() => onDelete(index)}
                    deleteIcon={<CancelIcon style={{ color: 'white' }} />}
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        borderRadius: 4,
                        backgroundColor: "#000F8E",
                        color: "white",
                        margin: "2px",
                        fontSize: '18px'
                    }}
                />
            ))}
        </>
    );

};

export default ChipList;
