import axios from 'axios';


const api = {
    
    getServices: async (page, size, filterData) => {
        const URL = `${process.env.REACT_APP_CATALOG_API}/query_page?page=${page}&size=${size}`;
        return await axios.post(URL, filterData)
            .then((res) => res.status === 200 ? res.data : [])
            .catch((err) => err);
    }, 
    
    getFilterData: async() => {
        const URL = `${process.env.REACT_APP_CATALOG_API}/api/filters-data`;
        return await axios.get(URL)
            .then((res) => res.status === 200 ? res.data : [])
            .catch((err) => err)
    }, 
    
    checkVC: async (VC) => {
        try {
            const URL = `${process.env.REACT_APP_VERIFIER_API}/api/rules`;
            const response = await axios.post(URL, VC);
                return {statusCode:200,data:response.data};
        } catch (error) {
              return {statusCode:403,data: error.response.data.message};
        }
    }
}

export default api;