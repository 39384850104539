import React, { useState } from "react";
import search from './assets/search.png';
import { Form, InputGroup, FormControl } from "react-bootstrap";

const SearchBar = ({handleValueChange , ...props}) => {
    const [value, setValue] = useState('')

    const handleChange = event => {
        setValue(event.target.value);
            handleValueChange(event.target.value)
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };


    return (
        <Form className="d-flex section-search position-relative w-100 px-4 px-lg-2">
            <img alt="search icon" src={search} className="position-absolute search-icon" />
            <InputGroup className="mb-3">
                <FormControl
                    type="search"
                    placeholder="Search by service name"
                    className="me-2 search"
                    aria-label="Search"
                    onChange={handleChange}
                    value={value}
                    {...props}
                    id="search-services"
                    onKeyDown={handleKeyDown}
                />
            </InputGroup>
        </Form>
    );
}

export default SearchBar