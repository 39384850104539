// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data {
    border: solid 1px #000F8E; 
}
  
.container-data {
    margin-left: 3%;
}

.tabs {
    pointer-events: auto;
}`, "",{"version":3,"sources":["webpack://./src/dataSet.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".data {\n    border: solid 1px #000F8E; \n}\n  \n.container-data {\n    margin-left: 3%;\n}\n\n.tabs {\n    pointer-events: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
