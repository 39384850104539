import React, {useState, useEffect} from 'react';

const BackToTopButton = () => {
    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setBackToTopButton(true);
            } else {
                setBackToTopButton(false);
            }
        });
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div>
            {backToTopButton && (
                <button className='back-to-top-button' style={{marginRight:15,borderRadius:10}} onClick={scrollUp}>
                    <svg width="30" height="12" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.46967 7.53033C0.176777 7.23744 0.176777 6.76256 0.46967 6.46967L6.46967 0.46967C6.76256 0.176777 7.23744 0.176777 7.53033 0.46967L13.5303 6.46967C13.8232 6.76256 13.8232 7.23744 13.5303 7.53033C13.2374 7.82322 12.7626 7.82322 12.4697 7.53033L7 2.06066L1.53033 7.53033C1.23744 7.82322 0.762563 7.82322 0.46967 7.53033Z" fill="#FFFFFF"/>
                    </svg>
                </button>
            )}
        </div>
    )
}

export default BackToTopButton;