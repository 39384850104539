import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

const transformData = (data) => {
    const transformedData = [];
    const groupedData = data.reduce((acc, item) => {
        const key = item.country.toLowerCase();
        acc[key] = acc[key] || { country: item.country, cities: ["All cities"] };
        if(!acc[key].cities.includes(item.urban_area)) {
            acc[key].cities.push(item.urban_area);
        }
        return acc;
    }, {});

    for (const key in groupedData) {
        const countryData = groupedData[key];
        if (countryData.country !== "") {
            transformedData.push(countryData);
        }
    }
    return transformedData;
};

const LocationDropdownComponent = ({ handlePaysChange, handleVilleChange, location }) => {
    const [selectedPays, setSelectedPays] = useState(null);
    const [selectedVille, setSelectedVille] = useState(null);

    const locationData = transformData(location);
    const PaysChange = (event, value) => {
        setSelectedPays(value);
        setSelectedVille(null);
        handlePaysChange(value);
    };

    const VilleChange = (event, value) => {
        setSelectedVille(value);
        handleVilleChange(value);
    };

    const filteredCities = selectedPays
        ? locationData.find((item) => item.country === selectedPays)?.cities || []
        : [];

    return (
        <>
            <Stack spacing={2} sx={{ width: 300, marginTop: '20px', display: 'inline' }}>
                <Autocomplete
                    id="Pays"
                    onChange={PaysChange}
                    options={locationData.map((option) => option.country)}
                    value={selectedPays}
                    filterOptions={(options, { inputValue }) =>
                        options.filter(option => {
                            const regex = new RegExp(`^${inputValue}`, 'i');
                            return regex.test(option);
                        })
                    }
                    renderInput={(params) => <TextField {...params} label="Pays" />}
                />
                <Autocomplete
                    onChange={VilleChange}
                    id="Ville"
                    options={filteredCities}
                    value={selectedVille}
                    filterOptions={(options, { inputValue }) =>
                        options.filter(option => {
                            const regex = new RegExp(`^${inputValue}`, 'i');
                            return regex.test(option);
                        })
                    }
                    renderInput={(params) => <TextField {...params} label="Ville" />}
                />
            </Stack>
        </>
    );
};

export default LocationDropdownComponent;
