import React from 'react';
import {Button} from 'react-bootstrap';
import cube from '../assets/cube.png';
import providerIcon from '../assets/provider-icon.png'
import settings from '../assets/settings.png';
import check from '../assets/check.png';

const FilterLayerComponent = ({ loading, filterData, handleFilter, title, children }) => {

    const renderButtons = () => {
        if(filterData!==undefined){
       if (title !== "Certification") {
            return filterData.map((item, index) => {
                if (title === "Layer") {
                    return (
                        <Button key={index} value={item} className='btn-type-service btn-layer' onClick={handleFilter}>{item}</Button>
                    );
                } else {
                    return (
                        <Button key={index} value={item} className='btn-type-service' onClick={handleFilter}>{item}</Button>
                    );
                }
            });
        }}
    };

    const chooseIcon = (title) => {
        switch (title) {
            case 'Certification':
                return check;
            case 'Type of Service':
                return settings;
            case 'Layer':
                return cube;
            case 'Provider':
                return providerIcon;
            default:
                return "";
        }
    }

    return (
        <div className='section-filter filter-label'>
            <div className='d-flex justify-content-start align-items-center'>
                <p><img alt='cube icon' className='me-2' width='20px' height='20px' src={chooseIcon(title)}></img></p>
                <p>{title}</p>
            </div>

            {loading ? (
                <div></div>
            ) : (
                <div>
                    {renderButtons()}
                </div>
            )}
            {children}
        </div>
    );
}

export default FilterLayerComponent;
